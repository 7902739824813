import React, { useEffect, useState } from "react";
import "./App.scss";
import Layout from "@ab-inbev/sam-layout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  ABIAppButton,
  useDocumentTitle,
} from "@ab-inbev-labs/ux-react-components";
import { useTranslation } from "react-i18next";

function App() {
  const i18next = useTranslation();
  const [validRedirect, setValidRedirect] = useState(true);
  const [appPath, setAppPath] = useState("");

  useDocumentTitle({
    title: validRedirect ? "Redirecting..." : i18next.t("appNotFound"),
  });

  // This page is hit via fallback rewrite when Azure can't resolve a path, either because the
  // path really doesn't exist (e.g. sam.ab-inbev.com/asdf) or because the browser is trying to
  // fetch what would normally be a client-side route (e.g. sam.ab-inbev.com/brewing-specials/create)
  useEffect(() => {
    // Split the current URL to determine how to route
    const pathTokens = window.location.pathname.split("/");

    // First token is empty because the path starts with "/",
    // so the second token is the path to the root of the app
    const appBaseRoute = pathTokens[1];

    // Any tokens after the app path are part of the app's client-side route
    const appSubRoute = pathTokens.splice(2).join("/");

    // If there was a client-side route in the URL, redirect to the root URL app
    // with the rest of the route in the `routeTo` param to be picked up by the app
    // If not, then this is a legitimate 404, so display the 'not found' content
    if (appSubRoute) {
      window.location.assign(
        `/${appBaseRoute}?routeTo=${appSubRoute}${encodeURIComponent(
          window.location.search,
        )}`,
      );
    } else {
      setAppPath(appBaseRoute);
      setValidRedirect(false);
    }
  }, [setValidRedirect]);

  const appNotFoundPage = (
    <>
      <div>{i18next.t("appPathDoesNotExist", { appPath: appPath })}</div>
      <ABIAppButton
        className="view-all-apps-button"
        onClick={() => window.location.assign("/apps")}
      >
        {i18next.t("returnToApps")}
      </ABIAppButton>
    </>
  );

  return (
    !validRedirect && (
      <BrowserRouter>
        <Layout appTitle={i18next.t("appNotFound")} showUserProfile={false}>
          <Routes>
            <Route path="*" element={appNotFoundPage} />
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  );
}

export default App;
