const translations = {
  "en-US": {
    translation: {
      appNotFound: "App Not Found",
      appPathDoesNotExist:
        "The application at URL '{{appPath}}' does not exist.",
    },
  },
};

export default translations;
